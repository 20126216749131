const { AccessLogsActionTypes } = require("./access-logs-actions");
const initialState = {
    isLoading: false,
    accessLogs: [],
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AccessLogsActionTypes.FETCHING_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case AccessLogsActionTypes.FETCHING_ACCESS_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                accessLogs: payload,
            };
        case AccessLogsActionTypes.ACCESS_LOG_DETAILS:
            return {
                ...state,
                details: payload,
            };

        default:
            return state;
    }
};

export default reducer;
