const { DashboardActionTypes } = require("./dashboard-actions");
const initialState = {
  dashboard: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DashboardActionTypes.FETCH_HOURLY_DATA:
      return {
        ...state,
        isLoadingHourly: true,
      };
    case DashboardActionTypes.FETCH_HOURLY_DATA_SUCCESS:
      return {
        ...state,
        isLoadingHourly: false,
        hourly: payload,
      };
    case DashboardActionTypes.FETCH_DAILY_DATA:
      return {
        ...state,
        isLoadingDaily: true,
      };
    case DashboardActionTypes.FETCH_DAILY_DATA_SUCCESS:
      return {
        ...state,
        isLoadingDaily: false,
        daily: payload,
      };
    case DashboardActionTypes.FETCH_HISTORY_DATA:
      return {
        ...state,
        isLoadingHistory: true,
      };
    case DashboardActionTypes.FETCH_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        isLoadingHistory: false,
        history: payload,
      };
    case DashboardActionTypes.FETCH_SUMMARY_DATA:
      return {
        ...state,
        isLoadingSummary: true,
      };
    case DashboardActionTypes.FETCH_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        isLoadingSummary: false,
        summary: payload,
      };
    default:
      return state;
  }
};

export default reducer;
