//Used by Oidc UserManager
export const SSO_REDIRECT_PATH = "/callback";

export const SSO_URL = process.env.NODE_ENV === "development" ? "https://sso.test.vggdev.com/identity" : window.env.ssoUrl;
export const REDIRECT_URI = process.env.NODE_ENV === "development" ? "http://localhost:3000/" : window.env.redirectUri;
export const POST_LOGOUT_REDIRECT_URI = process.env.NODE_ENV === "development" ? "http://localhost:3000/login" : window.env.postLogoutRedirectUri;
export const HOST_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : window.env.hostUrl;
export const CLIENT_ID = "ssoui";
export const RESPONSE_TYPE = "id_token token";
export const SCOPE = `openid profile identity-server-api ${CLIENT_ID}.roles`;

//For SSO UI
export const SSO_API_URL = process.env.NODE_ENV === "development" ? "https://ssoapi.test.vggdev.com" : window.env.ssoApiUrl;

// For Central Maker Checker
export const MAKER_CHECKER_API_URL = process.env.NODE_ENV === "development" ? "https://centralmakerchecker.test.vggdev.com" : window.env.makerCheckerApiUrl;

//Test Variable
export const TEST_VAR = process.env.NODE_ENV === "development" ? "You are on local..." : window.env.testVar;
