const { UserActionTypes } = require("./user-actions");
const initialState = {
  gettingCurrentUser: false,
  currentUser: null,
  userCallback: null,
  userDetails: {},
  allUsers: [],
  actionFor: "",
  clearance: [],
  pagingData: {},
  fetchingData: false,
  fetchingUser: false,
  processing: false,
  fetchingApplicationList: false,
  applicationList: [],
  vggSuperAdminClaims: [],
  vggAdminClaims: [],
  vggUserClaims: [],
  clientAdminClaims: [],
  clientUserClaims: [],
  userTabKey: "Details",
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UserActionTypes.SET_ACTION_FOR:
      return {
        ...state,
        actionFor: payload,
      };
    case UserActionTypes.GETTING_CURRENT_USER:
      return {
        ...state,
        gettingCurrentUser: payload,
      };
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };
    case UserActionTypes.PROCESSING_USER:
      return {
        ...state,
        processing: payload,
      };
    case UserActionTypes.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: payload,
      };
    case UserActionTypes.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: payload.map(data =>
          Object.assign({}, data, {
            FullName: `${data.FirstName}, ${data.LastName}`,
          })
        ),
      };
    case UserActionTypes.FETCH_PAGING:
      return {
        ...state,
        pagingData: payload,
      };
    case UserActionTypes.FETCH_USER_SIGNEDIN_CALLBACK_SUCCESS:
      return {
        ...state,
        userCallback: payload,
      };
    case UserActionTypes.FETCHING_DATA:
      return {
        ...state,
        fetchingData: payload,
      };
    case UserActionTypes.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...payload,
          FullName: `${payload.FirstName}, ${payload.LastName}`,
        },
      };
    case UserActionTypes.FETCHING_USER_DETAILS:
      return {
        ...state,
        fetchingUser: payload,
      };
    case UserActionTypes.FETCH_USER_CLEARANCE_SUCCESS:
      return {
        ...state,
        clearance: payload,
      };
    case UserActionTypes.FETCH_APPLICATION_LIST:
      return {
        ...state,
        fetchingApplicationList: true,
      };
    case UserActionTypes.FETCH_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        fetchingApplicationList: false,
        applicationList: payload,
      };
    case UserActionTypes.REMOVE_USER_CLAIMS:
      return {
        ...state,
        processing: true,
      };
    case UserActionTypes.ADD_USER_CLEARANCE:
      return {
        ...state,
        processing: true,
      };
    case UserActionTypes.ENABLE_CLIENT:
      return {
        ...state,
        processing: true,
      };
    case UserActionTypes.FETCH_SUPER_ADMIN_SUCCESS:
      return {
        ...state,
        vggSuperAdminClaims: payload,
      };
    case UserActionTypes.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        vggAdminClaims: payload,
      };
    case UserActionTypes.FETCH_VGG_USER_SUCCESS:
      return {
        ...state,
        vggUserClaims: payload,
      };
    case UserActionTypes.FETCH_CLIENT_ADMIN_SUCCESS:
      return {
        ...state,
        clientAdminClaims: payload,
      };
    case UserActionTypes.FETCH_CLIENT_USER_SUCCESS:
      return {
        ...state,
        clientUserClaims: payload,
      };
    case UserActionTypes.SET_USER_TAB_KEY:
      return {
        ...state,
        userTabKey: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
