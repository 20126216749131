import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import onboardingReducer from "../onboarding/onboarding-reducer";
import makerCheckerReducer from "../maker-checker/maker-checker-reducer";
import userReducer from "../user/user-reducer";
import scopeReducer from "../scopes/scopes-reducer";
import clientReducer from "../clients/clients-reducer";
import applicationReducer from "../applications/applications-reducer";
import otpReducer from "../otp/otp-reducer";
import auditReducer from "../audit/audit-reducer";
import profileReducer from "../profile/profile-reducer";
import dashboardReducer from "../dashboard/dashboard-reducer";
import accessLogReducer from "../access-logs/access-logs-reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["sso"],
};

const rootReducer = combineReducers({
  user: userReducer,
  onboarding: onboardingReducer,
  makerChecker: makerCheckerReducer,
  scopes: scopeReducer,
  clients: clientReducer,
  application: applicationReducer,
  otp: otpReducer,
  audit: auditReducer,
  accessLogs: accessLogReducer,
  dashboard: dashboardReducer,
  profile: profileReducer,
});

export default persistReducer(persistConfig, rootReducer);
