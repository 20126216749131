const { ScopesActionTypes } = require("./scopes-actions");
const initialState = {
  isLoading: false,
  scopes: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ScopesActionTypes.FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ScopesActionTypes.FETCHING_SCOPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scopes: payload,
      };
    case ScopesActionTypes.SCOPE_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ScopesActionTypes.CREATE_SCOPE:
      return {
        ...state,
        isCreateLoading: true,
      };
    case ScopesActionTypes.CREATE_SCOPE_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        newScope: payload,
      };
    case ScopesActionTypes.UPDATE_SCOPE:
      return {
        ...state,
        isCreateLoading: true,
      };
    case ScopesActionTypes.UPDATE_SCOPE_SUCCESS:
      return {
        ...state,
        isCreateLoading: false,
        newScope: payload,
      };
    case ScopesActionTypes.NEW_SCOPE_RESET:
      return {
        ...state,
        newScope: null,
      };

    default:
      return state;
  }
};

export default reducer;
