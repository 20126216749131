const { AuditActionTypes } = require("./audit-actions");
const initialState = {
  isLoading: false,
  audit: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AuditActionTypes.FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case AuditActionTypes.FETCHING_AUDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        audit: payload,
      };
    case AuditActionTypes.AUDIT_DETAILS:
      return {
        ...state,
        details: payload,
      };

    default:
      return state;
  }
};

export default reducer;
