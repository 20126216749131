const { ProfileActionTypes } = require("./profile-action");
const initialState = {
  isLoading: false,
  profile: undefined,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ProfileActionTypes.FETCH_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case ProfileActionTypes.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: payload,
      };
    case ProfileActionTypes.UPDATE_PROFILE:
      return {
        ...state,
        isUpdating: true,
      };
    case ProfileActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        profileUpdate: payload,
      };
    case ProfileActionTypes.PASSWORD_CHANGE:
      return {
        ...state,
        isChanging: true,
      };
    case ProfileActionTypes.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isChanging: false,
        passwordUpdate: payload,
      };
    default:
      return state;
  }
};

export default reducer;
