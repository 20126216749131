const { ClientsActionTypes } = require("./clients-actions");
const initialState = {
  isLoading: false,
  clients: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ClientsActionTypes.ADD_AUTHORIZATION_PROCESSING:
      return {
        ...state,
        addProcessing: true,
      };
    case ClientsActionTypes.ADD_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        addProcessing: false,
        addData: payload,
      };
    case ClientsActionTypes.ENABLE_AUTHORIZATION_PROCESSING:
      return {
        ...state,
        enableProcessing: true,
      };
    case ClientsActionTypes.ENABLE_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        enableProcessing: false,
        enableData: payload,
      };
    case ClientsActionTypes.DISABLE_AUTHORIZATION_PROCESSING:
      return {
        ...state,
        disableProcessing: true,
      };
    case ClientsActionTypes.DISABLE_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        disableProcessing: false,
        disableData: payload,
      };
    case ClientsActionTypes.LIST_AUTHORIZATION_PROCESSING:
      return {
        ...state,
        listProcessing: true,
      };
    case ClientsActionTypes.LIST_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        listProccessing: false,
        listData: payload,
      };
    case ClientsActionTypes.FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ClientsActionTypes.FETCHING_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        clients: payload,
      };
    case ClientsActionTypes.STOP_LOADER:
      return {
        ...state,
        isLoading: false,
        isCreatLoading: false,
      };
    case ClientsActionTypes.CLIENT_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ClientsActionTypes.CREATE_CLIENT:
      return {
        ...state,
        isCreatLoading: true,
      };
    case ClientsActionTypes.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        newClient: payload,
        isCreatLoading: false,
      };
    case ClientsActionTypes.UPDATE_CLIENT:
      return {
        ...state,
        isCreatLoading: true,
      };
    case ClientsActionTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        newClient: payload,
        isCreatLoading: false,
      };
    case ClientsActionTypes.NEW_CLIENT_RESET:
      return {
        ...state,
        newClient: null,
      };
    default:
      return state;
  }
};

export default reducer;
