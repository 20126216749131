const { ApplicationsActionTypes } = require("./applications-actions");
const initialState = {
  isLoading: false,
  applications: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ApplicationsActionTypes.FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_REPORT:
      return {
        ...state,
        isAppReportLoading: true,
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_USER:
      return {
        ...state,
        isUserLoading: true,
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_USER_SUCCESS:
      return {
        ...state,
        isUserLoading: false,
        applicationUser: payload,
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_REPORT_SUCCESS:
      return {
        ...state,
        isReportLoading: false,
        applicationReport: payload,
      };
    case ApplicationsActionTypes.FETCHING_APPLICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applications: payload,
      };
    case ApplicationsActionTypes.APPLICATION_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_USERS_ACCOUNT:
      return {
        ...state,
        isDetailLoading: true
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        applicationUserAccount: payload,
        isDetailLoading: false
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_USER_CLEARANCE:
      return {
        ...state,
        isApplicationUserClearanceLoading: true
      };
    case ApplicationsActionTypes.FETCH_APPLICATION_USER_CLEARANCE_SUCCESS:
      return {
        ...state,
        applicationUserClearance: payload,
        isApplicationUserClearanceLoading: false
      };
    case ApplicationsActionTypes.CREATE_BUSINESS_UNIT:
      return {
        ...state,
        isBULoading: true,
        newBusinessUnit: payload
      };
    case ApplicationsActionTypes.CREATE_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        isBULoading: false,
        newBusinessUnit: payload,
      };
    case ApplicationsActionTypes.CREATE_APPLICATION:
      return {
        ...state,
        isApplicationLoading: true,
      };
    case ApplicationsActionTypes.CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isApplicationLoading: false,
        newApplication: payload,
      };
    case ApplicationsActionTypes.CREATE_MAP_APPLICATION:
      return {
        ...state,
        isMapApplicationLoading: true,
      };
    case ApplicationsActionTypes.CREATE_MAP_APPLICATION_SUCCESS:
      return {
        ...state,
        isMapApplicationLoading: false,
        newMapApplication: payload,
      };
    case ApplicationsActionTypes.FETCHING_APPLICATION_LIST:
      return {
        ...state,
        isApplicationListLoading: true,
      };
    case ApplicationsActionTypes.FETCHING_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        isApplicationListLoading: false,
        applicationList: payload,
      };
    case ApplicationsActionTypes.FETCHING_BUSINESS_UNIT:
      return {
        ...state,
        isBusinessUnitLoading: true,
      };
    case ApplicationsActionTypes.FETCHING_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        isBusinessUnitLoading: false,
        sbuList: payload,
      };

    default:
      return state;
  }
};

export default reducer;
